import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import DateRangePicker from 'vue2-daterange-picker';
import { DateTime } from 'luxon';
import { DATE_FORMAT } from '@/helpers/date.helper';
import { Getter } from 'vuex-class';
import { isFunction } from 'lodash';

export interface DateRangeValues {
  startDate: null | string | Date;
  endDate: null | string | Date;
}

@Component({
  components: { DateRangePicker }
})
export default class DatepickerRange extends Vue {
  @Prop({
    type: String,
    default: null
  })
  startDate?;

  @Prop({
    type: String,
    default: null
  })
  endDate?;

  @Prop({
    type: String,
    default: null
  })
  size?;

  @Prop({
    type: Boolean,
    default: true
  })
  formGroup?;

  dateRange: DateRangeValues = {
    startDate: null,
    endDate: null,
  };
  localeData: any;
  ranges: any;

  @Getter('getCurrentLang', { namespace: 'lang' })
  currentLang!: string;

  @Watch('currentLang')
  changeDatepickerLocale(newValue: string, oldValue: string) {
    if (newValue !== oldValue) {
      this.initLocaleData();
      this.initRanges();
      this.$forceUpdate();
    }
  }

  @Watch('startDate')
  startDateChange(newVal: string, oldVal: string) {
    if (newVal !== oldVal && newVal === null) {
      this.dateRange.startDate = newVal;
    }
  }

  @Watch('endDate')
  endDateChange(newVal: string, oldVal: string) {
    if (newVal !== oldVal && newVal === null) {
      this.dateRange.endDate = newVal;
    }
  }

  created() {
    this.dateRange.startDate = this.startDate || null;
    this.dateRange.endDate = this.endDate || null;
    this.initLocaleData();
    this.initRanges();
  }

  updateDateValues(dateRange: DateRangeValues) {
    const startDate = this.dateToISO(dateRange.startDate);
    const endDate = this.dateToISO(dateRange.endDate);

    this.$emit('update:startDate', startDate);
    this.$emit('update:endDate', endDate);

    if (this.$listeners.update && isFunction(this.$listeners.update)) {
      this.$listeners.update(startDate, endDate);
    }
  }

  dateFormatter(value) {
    return value ? DateTime.fromJSDate(value).toFormat(DATE_FORMAT) : '';
  }

  dateToISO(value) {
    return value ? DateTime.fromJSDate(value).toISO() : null;
  }

  initLocaleData() {
    this.localeData = {
      firstDay: 1,
      format: 'yyyy-mm-dd',
      applyLabel: this.$t('datePicker.apply'),
      cancelLabel: this.$t('datePicker.cancel'),
      weekLabel: this.$t('datePicker.w'),
      customRangeLabel: this.$t('datePicker.customRange'),
      daysOfWeek: [
        this.$t('datePicker.sun'),
        this.$t('datePicker.mon'),
        this.$t('datePicker.tue'),
        this.$t('datePicker.wed'),
        this.$t('datePicker.thu'),
        this.$t('datePicker.fri'),
        this.$t('datePicker.sat')
      ],
      monthNames: [
        this.$t('datePicker.jan'),
        this.$t('datePicker.feb'),
        this.$t('datePicker.mar'),
        this.$t('datePicker.apr'),
        this.$t('datePicker.may'),
        this.$t('datePicker.jun'),
        this.$t('datePicker.jul'),
        this.$t('datePicker.aug'),
        this.$t('datePicker.sep'),
        this.$t('datePicker.oct'),
        this.$t('datePicker.nov'),
        this.$t('datePicker.dec'),
      ],
    };
  }

  initRanges() {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);
    yesterday.setHours(0, 0, 0, 0);

    const last7days = new Date();
    last7days.setDate(today.getDate() - 6);
    last7days.setHours(0, 0, 0, 0);

    const last30days = new Date();
    last30days.setDate(today.getDate() - 29);
    last30days.setHours(0, 0, 0, 0);

    const thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
    const thisMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    this.ranges = {
      [this.$t('datePicker.today') as string]: [today, today],
      [this.$t('datePicker.yesterday') as string]: [yesterday, yesterday],
      [this.$t('datePicker.last7Days') as string]: [last7days, today],
      [this.$t('datePicker.last30Days') as string]: [last30days, today],
      [this.$t('datePicker.thisMonth') as string]: [thisMonthStart, thisMonthEnd],
      [this.$t('datePicker.lastMonth') as string]: [new Date(today.getFullYear(), today.getMonth() - 1, 1), new Date(today.getFullYear(), today.getMonth(), 0)],
      [this.$t('datePicker.anyDates') as string]: [null, null],
    };
  }

  get classes() {
    return {
      [`form-control-${this.size}`]: this.size,
    };
  }
}
