import { getSocialNetworkList } from '@/constants/app.constants';
import { Component, Vue, Watch } from 'vue-property-decorator';
import Layout from '@/components/layouts/main.vue';
import PageTitle from '@/components/ui/page-title/pageTitle.vue';
import { AgGridVue } from 'ag-grid-vue';
import Box from '@/components/ui/box/box.vue';
import BoxHeader from '@/components/ui/box/box-header/boxHeader.vue';
import { AgGridHelper } from '@/helpers/ag-grid/ag-grid.helper';
import { Action, Getter } from 'vuex-class';
import { ICallbackAdditionalParams, ICallbackGeneralParams } from '@/helpers/ag-grid/ag-grid-datasource';
import { ColDef, GridOptions, ICellRendererParams } from 'ag-grid-community';
import { UserType } from '@/types/api/user/user.type';
import { UsersRequest, UsersStatisticsRequest } from '@/types/request/users-request.types';
import GridFilter from '@/components/ui/grid-filter/gridFilter.vue';
import DatepickerRange from '@/components/ui/inputs/datepicker-range/datepickerRange.vue';
import { debounceFilter, hasFilter, setEmptyToNull } from '@/helpers/filter.helper';
import ShowContent from '@/components/ui/modals/showContent.vue';
import { getSocialNetworkLink, getUserName, MODAL_CONFIG } from '@/helpers/app.helper';
import visibleHeader from '@/components/ui/ag-grid/header-components/visible-header/visibleHeader.vue';
import { DATE_FORMAT, DATE_TIME_FORMAT, getDateFromISO } from '@/helpers/date.helper';
import { UserStatisticsType } from '@/types/api/user/user-statistics.type';
import { ListResponseType } from '@/types/response/list-response.type';
import { DateTime } from 'luxon';

const title = 'users.title';

@Component({
  page: {
    title
  },
  components: {
    Layout,
    PageTitle,
    AgGridVue,
    Box,
    BoxHeader,
    GridFilter,
    DatepickerRange
  },
})
export default class Events extends Vue {
  title = title;
  agGrid: AgGridHelper | null = null;
  filter: any = this.initFilter();

  @Action('getUsers', { namespace: 'users' })
  getUsers!: (params: UsersRequest) => Promise<ListResponseType<UserType>>;

  @Action('getUserStatistics', { namespace: 'users' })
  getUserStatistics!: (params?: UsersStatisticsRequest) => Promise<UserStatisticsType>;

  @Getter('getUserStatistics', { namespace: 'users' })
  userStatistics!: UserStatisticsType;

  @Getter('getCurrentLang', { namespace: 'lang' })
  currentLang!: string;

  @Watch('currentLang')
  resetGridHeader(newValue: string, oldValue: string) {
    if (this.agGrid && newValue !== oldValue) {
      this.agGrid.getGridApi()!.refreshHeader();
    }
  }

  created(): void {
    this.debounceApplyFilter = debounceFilter(this.debounceApplyFilter);
    this.getUserStatistics()
  }

  beforeMount() {
    this.agGrid = new AgGridHelper(this.initGridOptions())
      .enableAutoSizeColumns(false)
      .enableInfinity(this.gridChangeCallback)
      .build();
  }

  gridChangeCallback(general: ICallbackGeneralParams, additional: ICallbackAdditionalParams) {
    additional.api.showLoadingOverlay();

    return this.getUsers({
      take: general.limit,
      skip: general.offset,
      ...setEmptyToNull(this.filter),
    })
      .then(response => {
        additional.api.hideOverlay();
        return {
          result: response.items,
          count: response.paging.totalCount
        }
      });
  }

  initGridOptions(): GridOptions {
    return {
      columnDefs: this.initColumnDef(),
      enableSorting: false,
      rowHeight: 140,
      headerHeight: 39,
      suppressHorizontalScroll: true,
    }
  }

  initColumnDef(): ColDef[] {
    return [
      {
        headerValueGetter: () => this.$t('users.nameContacts'),
        field: 'name',
        headerComponentFramework: visibleHeader,
        width: 400,
        minWidth: 400,
        cellRenderer: 'userInfoCellRenderer',
        cellRendererParams: {
          name: (params: ICellRendererParams) => params.data && getUserName(params.data),
          url: (params: ICellRendererParams) => `/user/update/${params.data?.id}`,
          phone: (params: ICellRendererParams) => params.data?.phoneNumber,
          email: (params: ICellRendererParams) => params.data?.email,
          avatar: (params: ICellRendererParams) => params.data?.picture,
        }
      },
      {
        headerValueGetter: () => this.$t('users.activityRegistrations'),
        field: 'activityRegistrations',
        width: 480,
        minWidth: 280,
        maxWidth: 480,
        cellRenderer: (params: ICellRendererParams) => {
          return `<div>${this.$t('users.lastActivity')}: ${getDateFromISO(params.data?.lastActivity, DATE_TIME_FORMAT)}</div>
          <div>${this.$t('users.registration')}: ${getDateFromISO(params.data?.createdAt, DATE_FORMAT)}</div>
          ${params.data?.socialName ? `<div>${this.$t('users.socialNetwork')}: ${params.data?.socialName || ''}</div>` : ''}
          ${params.data?.socialEmail ? `<div>${this.$t('users.socialEmail')}: ${params.data?.socialEmail}</div>` : ''}
          ${params.data?.socialProfileId ? `<div>${this.$t('users.socialProfileId')}: ${getSocialNetworkLink(params.data)}</div>` : ''}`

        }
      },
      {
        headerValueGetter: () => this.$t('users.flags'),
        field: 'flags',
        cellRenderer: 'badgesCellRenderer',
        cellRendererParams: {
          items: [
            { label: 'Admin: Miardi Gras', active: true },
            { label: 'OneFun Admin', active: false }
          ]
        },
      },
    ];
  }

  debounceApplyFilter() {
    this.applyFilter();
  }

  applyFilter() {
    if (this.agGrid) {
      this.agGrid.refreshInfinity();
    }
  }

  resetFilter() {
    this.filter = this.initFilter();
    if (this.agGrid) {
      this.agGrid.refreshInfinity();
    }
  }

  get socialNetworkNameOptions() {
    return [
      { value: null, text: this.$t('users.anySocialNetwork') },
      ...getSocialNetworkList()
    ];
  }

  initFilter() {
    return {
      name: null,
      phoneNumber: null,
      dateFrom: null,
      dateTo: null,
      email: null,
      socialNetwork: null,
    };
  }

  get isFilter() {
    return hasFilter(this.filter);
  }

  showContent(title: string, content: string) {
    this.$modal.show(ShowContent, { content, title, }, MODAL_CONFIG);
  }

  get totalRegistrations() {
    return this.userStatistics?.totalRegistrations || 0;
  }

  get totalActiveUsers() {
    return this.userStatistics?.totalActiveUsers || 0
  }

  applyRegistrationFilter() {
    this.getUserStatistics({
      dateFrom: DateTime.fromISO(this.filter.dateFrom!).toJSDate(),
      dateTo: DateTime.fromISO(this.filter.dateTo!).toJSDate(),
    });
    this.applyFilter();
  }
}
